import { graphql, useStaticQuery } from 'gatsby'
import { PublisherModel } from '../models'

export const getGraphPublishers = (): PublisherModel[] => {
  const {
    allPublishers: { nodes: publishers },
  } = useStaticQuery(graphql`
    query {
      allPublishers {
        nodes {
          description
          email
          facebook
          id
          image
          instagram
          name
          page
          twitter
          comics {
            id
          }
        }
      }
    }
  `)

  return publishers.length > 0 ? publishers : []
}
